<template>
  <div>
    <el-dialog
      v-loading="loading"
      title="订单详情"
      :visible.sync="showDialog"
      width="1200px"
      top="40px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="content-box">
        <!-- 基本信息 -->
        <div class="base-info">
          <span class="title">基本信息</span>

          <div class="base-info-table">
            <div class="thead">
              <div class="tr tr-order-no">
                <div class="th">订单号</div>
              </div>
              <div class="tr tr-order-buyer">
                <div class="th">买家</div>
              </div>
              <div class="tr tr-order-price">
                <div class="th">订单金额</div>
              </div>
              <div class="tr tr-order-payment">
                <div class="th">支付方式</div>
              </div>
              <div class="tr tr-order-express">
                <div class="th">配送方式</div>
              </div>
              <div class="tr tr-trade-status">
                <div class="th">交易状态</div>
              </div>
              <div class="tr tr-order-status">
                <div class="th">订单状态</div>
              </div>
            </div>

            <div class="tbody">
              <div class="tr tr-order-no">
                <div class="td">
                  <div class="order-no">{{ orderInfo.OrderNo }}</div>
                </div>
              </div>

              <div class="tr tr-order-buyer">
                <div class="td">
                  <div class="order-buyer">
                    <span class="buyer">{{ buyerData.UserName }}</span>
                    <span v-show="buyerData.MemberName" class="member"
                      >会员：{{ buyerData.MemberName }}</span
                    >
                    <span class="phone"
                      >(手机号：{{ buyerData.UserPhone }})</span
                    >
                  </div>
                </div>
              </div>

              <div class="tr tr-order-price">
                <div class="td">
                  <div class="order-price">
                    <div class="amount">
                      <span class="title">订单金额：</span>
                      <span class="price">¥ {{ orderInfo.OrderAmount }}</span>
                    </div>
                    <!-- <div class="express">
                      <span class="title">运费金额：</span>
                      <span class="price"
                        >+ ¥ {{ orderInfo.ExpressAmount }}</span
                      >
                    </div> -->
                    <div class="actual">
                      <span class="title">实付款金额：</span>
                      <span class="price">¥ {{ orderInfo.ActualAmount }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tr tr-order-payment">
                <div class="td">
                  <div class="order-payment">
                    <el-tag
                      v-if="paymentData.PayMethodText"
                      type="primary"
                      effect="dark"
                      >{{ paymentData.PayMethodText }}</el-tag
                    >
                  </div>
                </div>
              </div>

              <div class="tr tr-order-express">
                <div class="td">
                  <div class="order-express">
                    <el-tag
                      v-if="orderInfo.DeliveryModeText"
                      type="primary"
                      effect="dark"
                      >{{ orderInfo.DeliveryModeText }}</el-tag
                    >
                  </div>
                </div>
              </div>

              <div class="tr tr-trade-status">
                <div class="td">
                  <div class="trade-status">
                    <div>
                      <span>付款状态：</span>
                      <el-tag
                        :type="statusTimeData.PayMark ? 'success' : 'info'"
                        size="small"
                        effect="dark"
                        >{{
                          statusTimeData.PayMark ? "已付款" : "未付款"
                        }}</el-tag
                      >
                    </div>
                    <div>
                      <span>发货状态：</span>
                      <el-tag
                        :type="statusTimeData.DeliveryMark ? 'success' : 'info'"
                        size="small"
                        effect="dark"
                        >{{
                          statusTimeData.DeliveryMark ? "已发货" : "未发货"
                        }}</el-tag
                      >
                    </div>
                    <div>
                      <span>收货状态：</span>
                      <el-tag
                        :type="statusTimeData.ReceiptMark ? 'success' : 'info'"
                        size="small"
                        effect="dark"
                        >{{
                          statusTimeData.ReceiptMark ? "已收货" : "未收货"
                        }}</el-tag
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="tr tr-order-status">
                <div class="td">
                  <div class="order-status">
                    <el-tag
                      v-if="orderInfo.StatusText"
                      type="primary"
                      effect="plain"
                      >{{ orderInfo.StatusText }}</el-tag
                    >
                    <!-- <el-popconfirm title="这是一段内容确定删除吗？"> -->
                    <i
                      v-show="orderInfo.Status == 10"
                      class="el-icon-question"
                      @click="fixSqbPayOrder"
                    ></i>
                    <!-- </el-popconfirm> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 商品信息 -->
        <div class="goods-info">
          <span class="title">商品信息</span>

          <div class="goods-info-table">
            <div class="thead">
              <div class="tr tr-goods-info">
                <div class="th">商品名称</div>
              </div>
              <!-- <div class="tr tr-goods-no">
                <div class="th">商品编码</div>
              </div> -->
              <div class="tr tr-goods-price">
                <div class="th">单价</div>
              </div>
              <div class="tr tr-goods-count">
                <div class="th">购买数量</div>
              </div>
              <div class="tr tr-goods-amount">
                <div class="th">商品总价</div>
              </div>
            </div>

            <div class="tbody" v-for="(item, index) in itemList" :key="index">
              <div class="tr tr-goods-info">
                <div class="td">
                  <div class="goods-info">
                    <div class="image">
                      <el-image
                        :src="item.ItemImage"
                        fit="fill"
                        :lazy="true"
                      ></el-image>
                    </div>
                    <div class="info">
                      <span class="name">{{ item.ItemName }}</span>
                      <span v-if="item.AttrNameList" class="attr"
                        >选择的套餐：{{ item.AttrNameList }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="tr tr-goods-no">
                <div class="td">
                  <div class="goods-no"></div>
                </div>
              </div> -->

              <div class="tr tr-goods-price">
                <div class="td">
                  <div class="goods-price">
                    <span class="price">¥ {{ orderInfo.OrderAmount }}</span>
                    <!-- <span class="actual"
                      >会员折扣价：¥ {{ orderInfo.ItemDisc }}</span
                    > -->
                  </div>
                </div>
              </div>

              <div class="tr tr-goods-count">
                <div class="td">
                  <div class="goods-count">× {{ item.ItemCount }}</div>
                </div>
              </div>

              <div class="tr tr-goods-amount">
                <div class="td">
                  <div class="goods-amount">¥ {{ item.ActualAmount }}</div>
                </div>
              </div>
            </div>

            <div class="tfooter">
              <div class="remark">
                <span>买家留言：</span>
                <span class="text">{{ orderInfo.BuyerRemark || "无" }}</span>
              </div>

              <div class="total">
                <span>总计金额：</span>
                <span>¥ {{ orderInfo.ActualAmount }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 自提信息 -->
        <div v-if="orderInfo.DeliveryMode == 1" class="pickup-info">
          <span class="title">自提信息</span>

          <div class="pickup">
            <div class="pickup-name">自提人：{{ takeData.TakeContact }}</div>
            <div class="pickup-phone">自提人电话：{{ takeData.TakePhone }}</div>
          </div>

          <div class="store-info">
            <div class="store-name">
              门店名称：{{ takeData.TakeStoresName }}
            </div>
            <div class="store-phone">
              门店电话：{{ takeData.TakeStoresPhone }}
            </div>
            <div class="store-address">
              门店地址：{{ takeData.TakeStoresAddress }}
            </div>
          </div>
        </div>

        <!-- 收货信息 -->
        <div v-else-if="orderInfo.DeliveryMode != 0" class="take-info">
          <span class="title">商品信息</span>

          <div class="take-info-table">
            <div class="thead">
              <div class="tr tr-take-user">
                <div class="th">收货信息</div>
              </div>
              <div class="tr tr-take-phone">
                <div class="th">收获电话</div>
              </div>
              <div class="tr tr-take-address">
                <div class="th">收货地址</div>
              </div>
            </div>

            <div class="tbody">
              <div class="tr tr-take-user">
                <div class="td">
                  <div class="take-user">{{ takeData.TakeContact || "" }}</div>
                </div>
              </div>

              <div class="tr tr-take-phone">
                <div class="td">
                  <div class="take-phone">{{ takeData.TakePhone }}</div>
                </div>
              </div>

              <div class="tr tr-take-address">
                <div class="td">
                  <div class="take-address">
                    {{ takeData.TakeStoresAddress }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 付款信息 -->
        <div v-if="orderInfo.PayMark" class="payment-info">
          <span class="title">付款信息</span>

          <div class="payment-info-table">
            <div class="thead">
              <div class="tr tr-payment-amount">
                <div class="th">应付款金额</div>
              </div>
              <div class="tr tr-payment-name">
                <div class="th">支付方式</div>
              </div>
              <div class="tr tr-trade-no">
                <div class="th">支付流水号</div>
              </div>
              <div class="tr tr-payment-status">
                <div class="th">付款状态</div>
              </div>
              <div class="tr tr-payment-time">
                <div class="th">付款时间</div>
              </div>
            </div>

            <div class="tbody">
              <div class="tr tr-payment-amount">
                <div class="td">
                  <div class="payment-amount">
                    ¥ {{ paymentData.VipPayAmount || paymentData.SqbPayAmount }}
                  </div>
                </div>
              </div>

              <div class="tr tr-payment-name">
                <div class="td">
                  <div class="payment-name">
                    {{ paymentData.PayMethodText }}
                  </div>
                </div>
              </div>

              <div class="tr tr-trade-no">
                <div class="td">
                  <div class="trade-no">
                    {{ paymentData.SqbTradeNo || "----" }}
                  </div>
                </div>
              </div>

              <div class="tr tr-payment-status">
                <div class="td">
                  <div class="payment-status">
                    <el-tag type="primary" effect="dark">{{
                      paymentData.PayMethodText
                    }}</el-tag>
                  </div>
                </div>
              </div>

              <div class="tr tr-payment-time">
                <div class="td">
                  <div class="payment-time">
                    {{
                      util.dateFormat(
                        "YYYY-mm-dd HH:MM:SS",
                        new Date(paymentData.PayTime * 1000)
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 发货信息 -->
        <!-- <div v-if="orderInfo.Status == 20" class="verify-info">
          <span class="title">发货信息</span>

          <div class="verify-info-form">
            <el-form label-width="auto" :inline="false" size="small">
              <el-form-item label="物流公司">
                <el-input v-model=""></el-input>
              </el-form-item>
              <el-form-item label="物流单号">

              </el-form-item>
            </el-form>
            
          </div>
        </div> -->

        <!-- 取消订单 -->
        <div
          v-if="
            orderInfo.Status == 23 ||
              orderInfo.Status == 24 ||
              orderInfo.Status == 25
          "
          class="cancel-order"
        >
          <span class="title">退款信息</span>

          <div class="cancel-info">
            <span
              >退款状态:
              {{ statusTimeData.RefundMark ? "已退款" : "未退款" }}</span
            >
            <span>退款金额: ¥ {{ orderInfo.ActualAmount }}</span>
          </div>
        </div>

        <div v-if="type == 'check'" class="cancel-order">
          <span class="title">用户取消订单</span>

          <template v-if="orderInfo.Status == 23">
            <div class="tips">
              当前买家已付款并申请取消订单，请审核是否同意，如同意则自动退回付款金额（微信支付原路退款）并关闭订单。
            </div>

            <el-form label-width="120px" :inline="false" size="small">
              <el-form-item label="审核状态" required>
                <el-radio-group v-model="accept">
                  <el-radio :label="1">同意</el-radio>
                  <el-radio :label="0">拒绝</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label=" ">
                <el-button
                  type="primary"
                  size="small"
                  :disabled="auditDisabled"
                  @click="auditCanceling"
                  >确认审核</el-button
                >
              </el-form-item>
            </el-form>
          </template>
          <el-button
            v-else
            type="primary"
            size="small"
            :disabled="auditDisabled"
            @click="refundOrder"
            >重新退款</el-button
          >
        </div>

        <div
          v-if="orderInfo.Status == 40 || orderInfo.Status == 41"
          class="after-sale"
        >
          <span class="title">用户申请售后</span>

          <template v-if="orderInfo.Status == 40">
            <el-form label-width="120px" :inline="false" size="small">
              <el-form-item label="审核状态" required>
                <el-radio-group v-model="accept">
                  <el-radio :label="1">同意</el-radio>
                  <el-radio :label="0">拒绝</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label=" ">
                <el-button
                  type="primary"
                  size="small"
                  :disabled="afterSaleDisabled"
                  @click="auditAftersale"
                  >确认售后</el-button
                >
              </el-form-item>
            </el-form>
          </template>
          <el-button
            v-else
            type="primary"
            size="small"
            :disabled="auditDisabled"
            @click="refundOrder"
            >退款</el-button
          >
        </div>
        <div
          v-else-if="
            orderInfo.Status == 20 ||
              orderInfo.Status == 28 ||
              orderInfo.Status == 30
          "
          class="after-sale"
        >
          <span class="title">强制售后处理</span>
          <div class="tips">
            是否要将该订单进行售后处理，以便退回付款金额（微信支付原路退款）
          </div>
          <el-button
            type="primary"
            size="small"
            :disabled="auditDisabled"
            @click="forceAftersale"
            >确认处理</el-button
          >
        </div>

        <!-- 门店自提核销 -->
        <div
          class="verify-info"
          v-if="
            orderInfo.AutoVerify == 0 &&
              orderInfo.Status == 28 &&
              type != 'check'
          "
        >
          <span class="title">门店自提核销</span>

          <el-form label-width="120px" :inline="false" size="small">
            <el-form-item label="门店核销员" required>
              <el-select
                v-model="submitVerify.verifier_guid"
                placeholder="选择核销员"
                filterable
                @change="onChangeVerifyMan"
              >
                <el-option
                  v-for="item in verifyManList"
                  :key="item.VerifierGuid"
                  :label="
                    (item.Role ? '(管理员)' : '(员工)') + item.VerifierName
                  "
                  :value="item.VerifierGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="核销码" required>
              <el-input
                v-model="submitVerify.verify_code"
                placeholder="请输入自提人提供的 核销码"
                clearable
                @change=""
              ></el-input>
            </el-form-item>
            <el-form-item label=" ">
              <el-button
                type="primary"
                size="mini"
                :disabled="verifyDisabled"
                @click="verifyOrder"
                >确认核销</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onCloseDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

export default {
  data() {
    return {
      orderId: "",
      type: "detail",
      accept: 0,
      orderInfo: {
        ActualAmount: 0,
        BuyerRemark: "",
        DeliveryMode: 0,
        DeliveryModeText: "",
        DiscAmount: 0,
        ExpressAmount: 0,
        OrderAmount: 0,
        OrderId: 0,
        OrderNo: "",
        OrderType: 0,
        PayMark: 0,
        Status: 0,
        StatusText: "",
      },
      buyerData: {
        MemberGuid: "",
        MemberName: "",
        MemberPhone: "",
        UserId: "",
        UserName: "",
        UserPhone: "",
      },
      paymentData: {
        PayMethod: 0,
        PayMethodText: "",
        PayTime: 0,
        SqbPayAmount: 0,
        SqbTradeNo: "",
        VipPayAmount: 0,
        VipPayBillGuid: "",
        VipPayName: "",
      },
      statusTimeData: {
        CreateTime: 0,
        DeliveryMark: 0,
        DeliveryTime: 0,
        PayMark: 0,
        PayTime: 0,
        ReceiptMark: 0,
        ReceiptTime: 0,
        RefundMark: 0,
        RefundPayTime: 0,
      },
      takeData: {
        TakeContact: "",
        TakePhone: "",
        TakeStoresAddress: "",
        TakeStoresGuid: "",
        TakeStoresName: "",
        TakeStoresPhone: "",
      },
      verifyData: {
        VerifyAdminName: "",
        VerifyCode: "",
        VerifyMark: 0,
        VerifyStoresName: "",
        VerifyTime: 0,
      },
      submitVerify: {
        order_id: "",
        verifier_guid: "",
        verifier_name: "",
        verify_code: "",
      },
      itemList: [],
      verifyManList: [],
      loading: false,
      showDialog: false,
      verifyDisabled: false,
      auditDisabled: false,
      fixSqbDiabled: false,
      afterSaleDisabled: false,
    };
  },

  mounted() {},

  methods: {
    // 获取订单详细
    async getOrderMaintDetail() {
      let that = this;
      this.loading = true;
      try {
        let { data } = await Shop.getOrderMaintDetail({
          order_id: this.orderId,
        });
        let {
          BuyerData,
          PaymentData,
          StatusTimeData,
          TakeData,
          VerifyData,
          ItemList,
          ...orderInfo
        } = data;
        this.orderInfo = orderInfo;
        this.buyerData = BuyerData;
        this.paymentData = PaymentData;
        this.statusTimeData = StatusTimeData;
        this.takeData = TakeData;
        this.verifyData = VerifyData;
        this.itemList = ItemList;
        if (
          orderInfo.AutoVerify == 0 &&
          orderInfo.Status == 28 &&
          this.type != "check"
        ) {
          await that.getVerifierSelector();
        }
        await that.$nextTick(() => {
          that.loading = false;
          console.log(that.loading);
        });
      } catch (err) {
        that.loading = false;
        console.log(err);
      }
    },

    // 获取核销人员列表
    async getVerifierSelector() {
      try {
        let { data } = await Shop.getVerifierSelector();
        this.verifyManList = data;
        this.submitVerify.order_id = this.orderId;
      } catch (err) {
        console.log(err);
      }
    },

    // 退款
    async refundOrder() {
      this.auditDisabled = true;
      try {
        let { errcode } = await Shop.refundOrder({
          order_id: this.orderId,
        });
        if (errcode == 0) {
          this.$message.success("已退款");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.auditDisabled = false;
      }
    },

    // 补款（补货）
    async fixSqbPayOrder() {
      if (this.fixSqbDiabled) {
        return;
      }
      this.fixSqbDiabled = true;
      try {
        let { data, errcode } = await Shop.fixSqbPayOrder({
          order_id: this.orderId,
        });
        if (errcode == 0) {
          this.$message.success("修复成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.fixSqbDiabled = false;
      }
    },

    // 强制退款
    forceAftersale() {
      this.$confirm("是否确定强制售后处理？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.auditDisabled = true;
        try {
          let { errcode } = await Shop.forceAftersale({
            order_id: this.orderId,
          });
          if (errcode == 0) {
            // this.$message.success("已退款");
            this.$emit("success");
            this.getOrderMaintDetail();
            // this.onCloseDialog();
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.auditDisabled = false;
        }
      });
    },

    // 审核售后申请
    auditAftersale() {
      let that = this;
      this.$confirm("是否处理订单售后申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.afterSaleDisabled = true;
        try {
          let { errcode } = await Shop.auditAftersale({
            order_id: this.orderId,
            accept: this.accept,
          });
          if (errcode == 0) {
            this.$message.success("处理成功");
            this.$emit("success");
            this.resetData();
            this.getOrderMaintDetail();
            // await this.refundOrder();
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.afterSaleDisabled = false;
        }
      });
    },

    // 核销订单
    verifyOrder() {
      let that = this;
      let { submitVerify } = this;
      if (!submitVerify.verifier_guid) {
        return this.$message.error("请选择核销人员");
      } else if (!submitVerify.verify_code) {
        return this.$message.error("请输入核销码");
      }

      this.$confirm("确认核销订单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        that.verifyDisabled = true;

        try {
          let { errcode } = await Shop.verifyOrder(submitVerify);
          if (errcode == 0) {
            that.$message.success("核销成功");
            that.$emit("success");
            that.onCloseDialog();
          }
        } catch (err) {
          console.log(err);
        } finally {
          that.verifyDisabled = false;
        }
      });
    },

    // 审核订单申请
    auditCanceling() {
      let that = this;
      this.$confirm("是否处理订单审核申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Shop.auditCanceling({
            order_id: this.orderId,
            accept: this.accept,
          });
          if (errcode == 0) {
            await this.refundOrder();
          }
        } catch {
          console.log(err);
        }
      });
    },

    // 核销员改变事件
    onChangeVerifyMan(event) {
      this.submitVerify.verifier_name = this.verifyManList.find(
        (item) => item.VerifierGuid == event
      ).VerifierName;
      // console.log(this.submitVerify);
    },

    // 重置数据
    resetData() {
      this.orderInfo = {
        ActualAmount: 0,
        BuyerRemark: "",
        DeliveryMode: 0,
        DeliveryModeText: "",
        DiscAmount: 0,
        ExpressAmount: 0,
        OrderAmount: 0,
        OrderId: 0,
        OrderNo: "",
        OrderType: 0,
        PayMark: 0,
        Status: 0,
        StatusText: "",
      };
      this.buyerData = {
        MemberGuid: "",
        MemberName: "",
        MemberPhone: "",
        UserId: "",
        UserName: "",
        UserPhone: "",
      };
      this.paymentData = {
        PayMethod: 0,
        PayMethodText: "",
        PayTime: 0,
        SqbPayAmount: 0,
        SqbTradeNo: "",
        VipPayAmount: 0,
        VipPayBillGuid: "",
        VipPayName: "",
      };
      this.statusTimeData = {
        CreateTime: 0,
        DeliveryMark: 0,
        DeliveryTime: 0,
        PayMark: 0,
        PayTime: 0,
        ReceiptMark: 0,
        ReceiptTime: 0,
        RefundMark: 0,
        RefundPayTime: 0,
      };
      this.takeData = {
        TakeContact: "",
        TakePhone: "",
        TakeStoresAddress: "",
        TakeStoresGuid: "",
        TakeStoresName: "",
        TakeStoresPhone: "",
      };
      this.verifyData = {
        VerifyAdminName: "",
        VerifyCode: "",
        VerifyMark: 0,
        VerifyStoresName: "",
        VerifyTime: 0,
      };
      this.submitVerify = {
        order_id: "",
        verifier_guid: "",
        verifier_name: "",
        verify_code: "",
      };
    },

    // 打开对话框
    onShowDialog(orderId, type = "detail") {
      this.orderId = JSON.parse(JSON.stringify(orderId));
      this.type = type;
      this.getOrderMaintDetail();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  max-height: 70vh;
  overflow-y: auto;

  .base-info-table,
  .goods-info-table,
  .take-info-table,
  .payment-info-table {
    width: 100%;
    height: auto;
    max-height: var(--max-height);
    overflow-y: auto;

    * {
      box-sizing: border-box;
    }

    .thead,
    .tbody,
    .tfooter {
      .flex-row;
      width: 100%;
      height: 100%;

      .tr {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        .th,
        .td {
          padding: 10px;
        }
        .th {
          font-size: 15px;
          text-align: center;
          font-weight: bold;
        }
        .td {
          height: 100%;
        }
      }
      .tr:last-child {
        border-right: 1px solid #ddd;
      }
    }

    .thead {
      background: white;
      z-index: 10;
    }

    .tbody {
      align-items: unset;

      .tr {
        .flex-row;
        // align-items: unset;

        .flex-col;
        width: 100%;

        > div {
          width: 100%;
        }

        .td {
          > div {
            .flex-col;
            justify-content: center;
            height: 100%;
          }

          .goods-info {
            .flex-row;
            justify-content: unset;
            align-items: unset;

            .image {
              margin-right: 10px;
              .el-image {
                width: 70px;
                height: 70px;
              }
            }

            .info {
              .flex-col;

              span {
                width: 100%;
                line-height: 22px;
              }

              .name {
                .text-overflow(2);
              }

              .attr {
                .text-overflow(1);
              }
            }
          }

          .order-buyer .phone {
            color: #999;
          }
        }
      }

      &:last-child {
        border-bottom: 1px solid #ddd;
      }
    }

    .tfooter {
      padding: 10px;
      border: 1px solid #ddd;
    }
  }

  > div {
    .flex-col;
    align-items: unset;
    margin-bottom: 30px;

    > .title {
      padding: 0 10px;
      margin: 10px 0;
      color: black;
      font-size: 16px;
      border-left: 3px solid @primary-color;
    }
  }

  .base-info {
    .base-info-table {
      .thead,
      .tbody {
        .tr-order-no {
          flex: 1.5;
        }
        .tr-order-buyer {
          flex: 1.5;
        }
        .tr-order-price {
          flex: 1.5;
        }
        .tr-order-payment {
          flex: 1;
        }
        .tr-order-express {
          flex: 1;
        }
        .tr-trade-status {
          flex: 1.5;
        }
        .tr-order-status {
          flex: 1;
        }
      }

      .tbody {
        .trade-status {
          > div {
            margin-bottom: 5px;
          }
        }

        .order-status {
          flex-direction: row !important;
          i {
            font-size: 14px;
            color: #999;
            margin-inline-start: 5px;
          }
        }
      }
    }
  }

  .goods-info {
    .goods-info-table {
      .thead,
      .tbody {
        .tr-goods-info {
          flex: 1.5;
        }
        .tr-goods-price {
          flex: 1;
        }
        .tr-goods-count {
          flex: 1;
        }
        .tr-goods-amount {
          flex: 1;
        }
      }

      .tbody {
        .goods-info {
          .attr {
            color: #999;
          }
        }
      }

      .tfooter {
        align-items: unset;
        .remark {
          .flex-row;
          align-items: unset;
          margin-right: 20px;
          flex: 1;

          .text {
            flex: 1;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .pickup-info {
    .pickup,
    .store-info {
      .flex-row;
      align-items: unset;

      > div {
        margin: 10px;
      }
    }
  }

  .take-info {
    .take-info-table {
      .tr-take-user {
        flex: 1;
      }
      .tr-take-phone {
        flex: 1;
      }
      .tr-take-address {
        flex: 2;
      }
    }
  }

  .payment-info {
    .payment-info-table {
      .tr-payment-amount {
        flex: 1;
      }
      .tr-payment-name {
        flex: 1;
      }
      .tr-trade-no {
        flex: 1;
      }
      .tr-payment-status {
        flex: 1;
      }
      .tr-payment-time {
        flex: 1;
      }
    }
  }

  .verify-info,
  .cancel-order,
  .after-sale {
    .el-button {
      width: 100px;
    }
  }

  .cancel-order {
    .tips {
      padding: 10px;
      color: tomato;
      border: 1px solid tomato;
    }
    .cancel-info {
      .flex-row;
      align-items: unset;

      > span {
        margin: 10px;
      }
    }
  }

  .after-sale {
    .tips {
      padding-bottom: 10px;
    }
  }

  .verify-info {
    .el-form {
      .el-select,
      .el-input {
        width: 220px;
      }
    }
  }
}
</style>
